import React from 'react';
import { StaticQuery,graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Image from "gatsby-image";
import Header from '../../components/header';
import HeaderName from "../../svg/kwiaciarnia.svg";
import video from "../../images/kwiaciarniaBg.mp4";

const Section01 = ({dataPack}) => (

<section className={`${dataPack.allJson.edges[0].node.headerData[1].headerSection}`} id={`${dataPack.allJson.edges[0].node.headerData[1].headerLink}`}>

  <Container fluid >
    <Row  className="textScreen">
      <Col xl={1} lg={1} md={1} className="headerCont d-none d-md-flex flex-wrap align-content-center">
        <HeaderName />
      </Col>
      <Col xl={7} lg={5} md={5} className="paragraph-horizontal align-content-center">

        <div className="text-horizontal"
          dangerouslySetInnerHTML={{ __html: dataPack.allMarkdownRemark.edges[0].node.excerpt}}
        />

      </Col>
      <Col xl={4} lg={6} md={6} className="col-right">
      </Col>

    </Row>
    <Row noGutters className="screen">
      <Col xl={8} lg={6} md={6} className="d-none d-md-block">
        <Image
          fluid={dataPack.allMarkdownRemark.edges[0].node.frontmatter.imageBg.childImageSharp.fluid}
        />
      </Col>
      <Col xl={4} lg={6} md={6} className="bg-video">
        <video
                height="auto"
                width="100%"
                loop
                muted
                autoPlay>
          <source src={video} type="video/mp4" />
        </video>
      </Col>

      <Col className="d-block d-sm-none background-sm">
      </Col>
    </Row>
  </Container>
</section>
  )

export default props =>(
<StaticQuery query={graphql`
  query section01Query{
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/section01/pl/" } }
    ) {
      edges {
        node {
          excerpt(format: HTML, pruneLength: 1000)
          frontmatter {
            imageBg{
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            }
          }
        }
      }
    allJson(sort: {fields: headerData}, limit: 1) {
      edges {
        node {
          headerData{
              headerName
              headerLink
              headerSection
              headerImage{
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
  }
`}
render={data=><Section01 dataPack={data}{...props} />}
/>
)
